import React from "react";
import { ContextType } from "../store/Store";
import Onboarding from "../components/organisms/Onboarding";
import { AddressForm } from "../components/organisms/AddressForm";
import { NoPlaygrounds } from "../components/molecules/NoPlaygrounds";
import { Redirect } from "react-router-dom";
import { PlaygroundList } from "../components/organisms/PlaygroundList";

export const getCorrectPlaygroundsComponent = (context: ContextType): any => {
  const { state } = context;

  if (!state.user.hasSeenOnboarding) {
    return <Onboarding />;
  }
  if (state.user.address === null) {
    return <AddressForm />;
  }

  const adminWarning =
    state.user.roles.includes("ROLE_ADMIN") ||
    state.user.roles.includes("ROLE_CLIENT") ? (
      <div className={"message message--warning"} role={"alert"}>
        U bekijkt deze pagina als beheerder, en ziet daarom alle speeltuinen
        ongeacht van uw adres of fase waarin de speeltuin zit.
      </div>
    ) : null;

  if (state.playgrounds?.length === 0) {
    return (
      <div className={"block"}>
        {adminWarning}
        <NoPlaygrounds />
      </div>
    );
  }

  if (state.playgrounds?.length === 1) {
    const url = "/playground/" + state.playgrounds[0].id;
    return <Redirect to={url} />;
  }

  if (state.playgrounds?.length && state.playgrounds.length > 1) {
    return (
      <div className={"block"}>
        {adminWarning}
        <PlaygroundList />
      </div>
    );
  }

  return null;
};
