import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import { getPlaygroundById } from "../helpers/PlaygroundHelper";
import NavigationBar from "../components/organisms/NavigationBar";
import { TileExplanation } from "../components/molecules/TileExplanation";
import { ActivitiesSelect } from "../components/organisms/ActivitiesSelect";

export const PlaygroundActivitiesPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId), context);

  const [seenDescription, setSeenDescription] = useState(false);

  useEffect(() => {
    document.title = "Jullie activiteiten - Speeltuinchef";
  });

  if (playground === null) {
    return <div role={"status"}>Laden..</div>;
  } else if (playground.activities.length === 0) {
    location.href = "/playground/" + playground.id;
    return <div></div>;
  }

  if (!seenDescription) {
    return (
      <div>
        <NavigationBar />
        <main>
          <TileExplanation
            imageUrl={"/assets/images/activity_tile.svg"}
            imageDescription={"V"}
            title={"Jullie activiteiten"}
            text={
              "Wat doen jullie buiten? Ren je altijd rondjes of klim je graag? Kies de activiteiten die bij jou passen! Je mag maximaal " +
              playground.maxSelectableActivities +
              " activiteiten kiezen. Veel plezier!"
            }
            setSeenDescription={setSeenDescription}
          />
        </main>
      </div>
    );
  } else {
    return (
      <div>
        <NavigationBar />
        <main>
          <ActivitiesSelect playground={playground} />
        </main>
      </div>
    );
  }
};
