import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import NavigationBar from "../components/organisms/NavigationBar";
import {
  requestPasswordNew,
  requestPasswordReset,
} from "../api/user/UserCalls";

export const PasswordResetPage = () => {
  const { resetToken } = useParams<{ resetToken: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [warningText, setWarningText] = useState("");

  const history = useHistory();

  const handleRequestEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!email || email.length < 5) {
      setWarningText(
        "Geen geldig e-mail adres ingevuld, het ingevulde e-mail adres moet minimaal 5 tekens bevatten"
      );
      setIsLoading(false);
      return;
    }

    try {
      await requestPasswordReset(email);
      history.push("/");
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    document.title = "Wachtwoord resetten - Speeltuinchef";
  });

  const handleNewPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!passwordsOk()) {
      setIsLoading(false);
      return;
    }

    try {
      await requestPasswordNew(newPassword, resetToken);
      history.push("/");
    } catch (e) {
      console.error(e);
    }
  };

  const passwordsOk = (): boolean => {
    if (newPassword.length < 8) {
      setWarningText(
        "Nieuw wachtwoord voldoet niet aan de eisen. Wachtwoord moet uit minimaal 8 tekens bestaan."
      );
      return false;
    }
    if (newPassword !== confirmNewPassword) {
      setWarningText(
        "De gekozen wachtwoorden zijn niet gelijk. Type uw wachtwoord nogmaals in."
      );
      return false;
    }

    return true;
  };

  if (resetToken) {
    const warning =
      warningText === "" ? null : (
        <div className={"message message--warning"} role={"alert"}>
          {warningText}
        </div>
      );

    return (
      <div>
        <NavigationBar />
        <main>
          <div className={"container login"}>
            <div className={"login__logo"}>
              <img
                src="/assets/images/chef_big.png"
                alt="De speeltuinchef. Speeltuinen koken met jouw ingrediënten! Chefkok die speeltoestellen in grote pan roert. Tijdens het roeren zegt hij 'Lekker hoor!' en vraagt hij 'Weet jij nog wat lekkere ingrediënten?'"
              />
            </div>
            <div className={"login__form"}>
              <p>Kies uw nieuwe wachtwoord.</p>
              {warning}
              <form onSubmit={handleNewPassword}>
                <fieldset>
                  <label htmlFor="password">Nieuw wachtwoord</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    autoComplete={"new-password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder={"Nieuw wachtwoord"}
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="confirmPassword">
                    Bevestig nieuw wachtwoord
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    autoComplete={"new-password"}
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    placeholder={"Bevestig nieuw Wachtwoord"}
                  />
                </fieldset>
                <button className={"button"}>
                  {isLoading ? (
                    <img src={"/assets/images/loader.svg"} />
                  ) : (
                    "Bewaar wachtwoord"
                  )}
                </button>
              </form>
            </div>
          </div>
        </main>
      </div>
    );
  }

  const warning =
    warningText === "" ? null : (
      <div className={"message message--warning"} role={"alert"}>
        {warningText}
      </div>
    );

  return (
    <div>
      <NavigationBar />
      <div className={"container login"}>
        <div className={"login__logo"}>
          <img
            src="/assets/images/chef_big.png"
            alt="De speeltuinchef. Speeltuinen koken met jouw ingrediënten! Chefkok die speeltoestellen in grote pan roert. Tijdens het roeren zegt hij 'Lekker hoor!' en vraagt hij 'Weet jij nog wat lekkere ingrediënten?'"
          />
        </div>
        <div className={"login__form"}>
          <p>
            Vul uw e-mail adres in waarmee u geregistreerd heeft. Wanneer het
            e-mail adres bij ons bekend is ontvangt u hier een mail met verdere
            instructies.
          </p>
          <form onSubmit={handleRequestEmail}>
            {warning}
            <fieldset>
              <label htmlFor={"email"}>E-mailadres</label>
              <input
                type="email"
                name="email"
                id="email"
                autoComplete={"email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={"E-mailadres"}
              />
            </fieldset>
            <button className={"button"}>
              {isLoading ? (
                <img src={"/assets/images/loader.svg"} />
              ) : (
                "Reset wachtwoord"
              )}
            </button>
          </form>
        </div>
        <div className={"sitemap__link"}>
          <Link to={"/sitemap"} arial-label={"Bezoek de sitemap"}>
            Sitemap
          </Link>
        </div>
      </div>
    </div>
  );
};
