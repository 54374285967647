import React, { useContext, useState } from "react";
import { PlaygroundType } from "../../models/PlaygroundType";
import { ActivityTile } from "../molecules/ActivityTile";
import { ActivityType } from "../../models/ActivityType";
import { toggleActivityInShoppingList } from "../../helpers/ShoppinglistHelper";
import { SpeeltuinchefContext } from "../../store/Store";
import { ChefWarning } from "../molecules/ChefWarning";
import { updateShoppingList } from "../../api/playgrounds/PlaygroundsCalls";
import { useHistory } from "react-router-dom";
import { BottomActionBar } from "./BottomActionBar";

export type ActivitiesSelectProps = {
  playground: PlaygroundType;
};

export const ActivitiesSelect = (props: ActivitiesSelectProps) => {
  const context = useContext(SpeeltuinchefContext);
  const [hasTooMuchActivities, setHasTooMuchActivities] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const saveAndContinue = async () => {
    const { state } = context;
    if (state.session.token === null) {
      location.href = "/login";
      return;
    }
    setIsLoading(true);
    await updateShoppingList(
      state.session.token,
      props.playground,
      props.playground.shoppingList
    );
    history.push("/playground/" + props.playground.id);
  };

  const selectActivity = (activity: ActivityType) => {
    if (
      props.playground.shoppingList.activities.length >=
        props.playground.maxSelectableActivities &&
      !props.playground.shoppingList.activities.includes(activity.id)
    ) {
      setHasTooMuchActivities(true);
    } else {
      toggleActivityInShoppingList(activity, props.playground, context);
    }
  };

  const tooMuchActivitiesWarning = hasTooMuchActivities ? (
    <ChefWarning
      text={
        "Je mag maximaal " +
        props.playground.maxSelectableActivities +
        " activiteiten selecteren!"
      }
      closeFunction={setHasTooMuchActivities}
    />
  ) : null;

  return (
    <div className={"block "}>
      <h1>{props.playground.name}</h1>
      {tooMuchActivitiesWarning}
      <div className={"playgroundDashboard"}>
        {getRenderedActivities(props.playground, selectActivity)}
      </div>
      <BottomActionBar
        text={"Verder"}
        isLoading={isLoading}
        clickFunction={saveAndContinue}
      />
    </div>
  );
};

const getRenderedActivities = (
  playground: PlaygroundType,
  selectActivity: (activity: ActivityType) => void
) => {
  return playground.activities.map((activity) => {
    return (
      <ActivityTile
        key={activity.id}
        activity={activity}
        selectActivity={selectActivity}
        playground={playground}
      />
    );
  });
};
