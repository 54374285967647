import React, { useContext, useEffect, useState } from "react";
import NavigationBar from "../components/organisms/NavigationBar";
import { TileExplanation } from "../components/molecules/TileExplanation";
import { useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../store/Store";
import {
  getPlaygroundById,
  getPlaygroundCurrentPhase,
} from "../helpers/PlaygroundHelper";
import { ConceptSelect } from "../components/organisms/ConceptSelect";

export const PlaygroundConceptsPage = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId), context);

  const [seenDescription, setSeenDescription] = useState(false);

  useEffect(() => {
    document.title = "Ontwerpen - Speeltuinchef";
  });

  if (playground === null) {
    return <div role={"status"}>Laden..</div>;
  } else if (
    playground.concepts.length === 0 ||
    getPlaygroundCurrentPhase(playground) !== "CONCEPTS_VOTING"
  ) {
    location.href = "/playground/" + playground.id;
    return <div></div>;
  }

  if (!seenDescription) {
    return (
      <div>
        <NavigationBar />
        <main>
          <TileExplanation
            imageUrl={"/assets/images/concept_tile.svg"}
            imageDescription={""}
            title={"Ontwerpen"}
            text={
              playground.concepts.length === 1
                ? "Het ontwerp is gereed! Ik heb alle boodschappenlijstjes goed bekeken en dit is het resultaat. Bekijk het ontwerp rustig en druk op het hartje als je het ontwerp leuk vindt. Heb je het ontwerp bekeken? Je kan een reactie geven op het ontwerp voordat we echt gaan starten met de herinrichting."
                : "De ontwerpen zijn er! Ik heb alle ingezonden boodschappenlijstjes goed bekeken en " +
                  playground.concepts.length +
                  " ontwerpen gemaakt. Bekijk ze rustig en stem door op het hartje te drukken. Helemaal klaar? Dan maar hopen dat jouw favoriet uitgevoerd wordt! Succes!"
            }
            setSeenDescription={setSeenDescription}
          />
        </main>
      </div>
    );
  } else {
    return (
      <div>
        <NavigationBar />
        <main>
          <ConceptSelect playground={playground} />
        </main>
      </div>
    );
  }
};
