import React from "react";
import { PlaygroundType } from "../models/PlaygroundType";
import { ContextType } from "../store/Store";
import { Tile } from "../components/molecules/Tile";
import { Link } from "react-router-dom";

export const getPlaygroundById = (
  playgroundId: number,
  context: ContextType
): PlaygroundType | null => {
  const { state } = context;

  if (!state.playgrounds) {
    return null;
  }

  let returnPlayground = null;
  state.playgrounds.forEach((playground) => {
    if (playground.id === playgroundId) {
      returnPlayground = playground;
    }
  });

  return returnPlayground;
};

export const getPlaygroundTiles = (
  playground: PlaygroundType
): Array<React.ReactNode> => {
  const phase = getPlaygroundCurrentPhase(playground);
  const endDate = getPhaseEndDate(playground, phase);
  const timeToGoString = getTimeToGoString(endDate);
  const tiles = [];

  if (phase === "PREFERENCES" && !playground.shoppingList.isFinalized) {
    if (playground.hasQuiz) {
      tiles.push(
        <Tile
          key={"quiz"}
          linkUrl={"/playground/" + playground.id + "/quiz"}
          title={"Quiz"}
          imageDescription={"Vraagtekens"}
          imageUrl={"/assets/images/quiz_tile.svg"}
          isFinished={false}
        />
      );
    }

    if (playground.hasQuestionnaire) {
      tiles.push(
        <Tile
          key={"vragenlijst"}
          linkUrl={"/playground/" + playground.id + "/questionnaire"}
          title={"Enquête"}
          imageDescription={"Vraagtekens"}
          imageUrl={"/assets/images/quiz_tile.svg"}
          isFinished={playground.shoppingList.answers.length > 0}
        />
      );
    }
    if (playground.hasFamily) {
      tiles.push(
        <Tile
          key={"family"}
          linkUrl={"/playground/" + playground.id + "/family"}
          title={"Jullie thuis"}
          imageDescription={"Een gezin"}
          imageUrl={"/assets/images/family_tile.svg"}
          isFinished={playground.shoppingList.family.length > 0}
        />
      );
    }
    tiles.push(
      <Tile
        key={"moodboard"}
        linkUrl={"/playground/" + playground.id + "/moodboard"}
        title={"Jullie smaak"}
        imageDescription={"Een moodboard met verschillende gekleurde vlakken"}
        imageUrl={"/assets/images/moodboard_tile.svg"}
        isFinished={playground.shoppingList.moodboards.length > 0}
      />
    );

    if (playground.hasActivity) {
      tiles.push(
        <Tile
          key={"activity"}
          linkUrl={"/playground/" + playground.id + "/activity"}
          title={"Jullie<br/>activiteiten"}
          imageDescription={"Verschillende woord ballonnen"}
          imageUrl={"/assets/images/activity_tile.svg"}
          isFinished={playground.shoppingList.activities.length > 0}
        />
      );
    }
    tiles.push(
      <Tile
        key={"ingredients"}
        linkUrl={"/playground/" + playground.id + "/ingredients"}
        title={"Jullie ingrediënten"}
        imageDescription={"Verschillende speeltoestellen"}
        imageUrl={"/assets/images/ingredient_tile.svg"}
        isFinished={playground.shoppingList.ingredients.length > 0}
      />
    );
    tiles.push(
      <Tile
        key={"remark"}
        linkUrl={"/playground/" + playground.id + "/remark"}
        title={"Jullie aanvullingen"}
        imageDescription={"Woordballon met puntjes er in"}
        imageUrl={"/assets/images/remark_tile.svg"}
        isFinished={playground.shoppingList.remark !== null}
      />
    );
    tiles.push(
      <Tile
        key={"countdown"}
        title={"Tik tak! Tik Tak! " + timeToGoString}
        imageDescription={"Groen klokje"}
        imageUrl={"/assets/images/clock_tile.svg"}
        isFinished={false}
      />
    );

    if (playground.externalPageUrl) {
      tiles.push(
        <a
          key={"projectpage"}
          href={playground.externalPageUrl}
          target={"_blank"}
          rel={"noreferrer"}
          aria-label={"Open projectpagina op externe website"}
        >
          <Tile
            title={"Project pagina"}
            imageDescription={"Woordballon met puntjes er in"}
            imageUrl={"/assets/images/remark_tile.svg"}
            isFinished={false}
          />
        </a>
      );
    }

    tiles.push(
      <Link
        key={"shoppinglist"}
        to={"/playground/" + playground.id + "/shopping-list"}
      >
        <button
          className={"button"}
          aria-label={"Bekijk uw boodscahppenlijstje en stuur hem in"}
        >
          Boodschappenlijstje bekijken en insturen
        </button>
      </Link>
    );

    return tiles;
  } else if (
    phase === "AWAIT_CONCEPTS" ||
    (phase === "PREFERENCES" && playground.shoppingList.isFinalized)
  ) {
    if (playground.hasQuiz) {
      tiles.push(
        <Tile
          key={"quiz"}
          linkUrl={"/playground/" + playground.id + "/quiz"}
          title={"Quiz"}
          imageDescription={"Vraagtekens"}
          imageUrl={"/assets/images/quiz_tile.svg"}
          isFinished={false}
        />
      );
    }
    tiles.push(
      <Tile
        key={"countdown"}
        title={"Wachten op ontwerpen"}
        imageDescription={"Rood klokje"}
        imageUrl={"/assets/images/clock_tile_red.svg"}
        isFinished={false}
      />
    );

    if (playground.externalPageUrl) {
      tiles.push(
        <a
          key={"projectpage"}
          href={playground.externalPageUrl}
          target={"_blank"}
          rel={"noreferrer"}
          aria-label={"Open projectpagina op externe website"}
        >
          <Tile
            title={"Project pagina"}
            imageDescription={"Woordballon met puntjes er in"}
            imageUrl={"/assets/images/remark_tile.svg"}
            isFinished={false}
          />
        </a>
      );
    }
    return tiles;
  } else if (phase === "CONCEPTS_VOTING") {
    if (playground.hasQuiz) {
      tiles.push(
        <Tile
          key={"quiz"}
          linkUrl={"/playground/" + playground.id + "/quiz"}
          title={"Quiz"}
          imageDescription={"Vraagtekens"}
          imageUrl={"/assets/images/quiz_tile.svg"}
          isFinished={false}
        />
      );
    }

    tiles.push(
      <Tile
        key={"coneptvoting"}
        title={"Ontwerpen"}
        linkUrl={"/playground/" + playground.id + "/concepts"}
        imageDescription={"Weide met een boomhut, wipkip en glijbaan"}
        imageUrl={"/assets/images/concept_tile.svg"}
        isFinished={playground.shoppingList.votedConcept !== null}
      />
    );
    tiles.push(
      <Tile
        key={"countdown"}
        title={"Tik tak! " + timeToGoString}
        imageDescription={"Groen klokje"}
        imageUrl={"/assets/images/clock_tile.svg"}
        isFinished={false}
      />
    );
    if (playground.externalPageUrl) {
      tiles.push(
        <a
          key={"projectpage"}
          href={playground.externalPageUrl}
          target={"_blank"}
          rel={"noreferrer"}
          aria-label={"Open projectpagina op externe website"}
        >
          <Tile
            title={"Project pagina"}
            imageDescription={"Woordballon met puntjes er in"}
            imageUrl={"/assets/images/remark_tile.svg"}
            isFinished={false}
          />
        </a>
      );
    }
    return tiles;
  } else if (phase === "AWAIT_BUILD") {
    if (playground.hasQuiz) {
      tiles.push(
        <Tile
          key={"quiz"}
          linkUrl={"/playground/" + playground.id + "/quiz"}
          title={"Quiz"}
          imageDescription={"Vraagtekens"}
          imageUrl={"/assets/images/quiz_tile.svg"}
          isFinished={false}
        />
      );
    }

    if (playground.externalPageUrl) {
      tiles.push(
        <a
          key={"projectpage"}
          href={playground.externalPageUrl}
          target={"_blank"}
          rel={"noreferrer"}
          aria-label={"Open projectpagina op externe website"}
        >
          <Tile
            title={"Project pagina"}
            imageDescription={"Woordballon met puntjes er in"}
            imageUrl={"/assets/images/remark_tile.svg"}
            isFinished={false}
          />
        </a>
      );
    }

    tiles.push(
      <div key={"msg"}>
        De stemmen zijn geteld! Je hoort binnenkort meer via de gemeente over de
        realisatie van de speeltuin!
      </div>
    );

    return tiles;
  } else if (phase === "FINISHED") {
    if (playground.externalPageUrl) {
      tiles.push(
        <a
          key={"projectpage"}
          href={playground.externalPageUrl}
          target={"_blank"}
          rel={"noreferrer"}
          aria-label={"Open projectpagina op externe website"}
        >
          <Tile
            title={"Project pagina"}
            imageDescription={"Woordballon met puntjes er in"}
            imageUrl={"/assets/images/remark_tile.svg"}
            isFinished={false}
          />
        </a>
      );
    }
    tiles.push(
      <div key={"msg"}>
        <div className={"message message--warning"} role={"alert"}>
          De aanpassingen aan de speeltuin zijn afgerond!
        </div>
      </div>
    );

    return tiles;
  } else {
    //concept
    return [
      <div key={"msg"} className={"block"}>
        <div className={"message message--warning"} role={"alert"}>
          Deze speeltuin is nog niet open voor participatie, nog even geduld!
        </div>
      </div>,
    ];
  }
};

export const getPlaygroundSiteMap = (
  playground: PlaygroundType
): Array<React.ReactNode> => {
  const phase = getPlaygroundCurrentPhase(playground);
  const sitemapList = [];

  if (phase === "PREFERENCES" && !playground.shoppingList.isFinalized) {
    if (playground.hasQuiz) {
      sitemapList.push(
        <li>
          <Link
            to={"/playground/" + playground.id + "/quiz"}
            aria-label={"Quiz " + playground.name}
          >
            Quiz
          </Link>
        </li>
      );
    }

    if (playground.hasQuestionnaire) {
      sitemapList.push(
        <li>
          <Link
            to={"/playground/" + playground.id + "/questionnaire"}
            aria-label={"Vragenlijst " + playground.name}
          >
            Enquête
          </Link>
        </li>
      );
    }
    if (playground.hasFamily) {
      sitemapList.push(
        <li>
          <Link
            to={"/playground/" + playground.id + "/family"}
            aria-label={"Jullie thuis " + playground.name}
          >
            Jullie thuis
          </Link>
        </li>
      );
    }
    sitemapList.push(
      <li>
        <Link
          to={"/playground/" + playground.id + "/moodboard"}
          aria-label={"Jullie smaak " + playground.name}
        >
          Jullie smaak
        </Link>
      </li>
    );
    if (playground.hasActivity) {
      sitemapList.push(
        <li>
          <Link
            to={"/playground/" + playground.id + "/activity"}
            aria-label={"Jullie activiteiten " + playground.name}
          >
            Jullie activiteiten
          </Link>
        </li>
      );
    }
    sitemapList.push(
      <li>
        <Link
          to={"/playground/" + playground.id + "/ingredients"}
          aria-label={"Jullie ingrediënten " + playground.name}
        >
          Jullie ingrediënten
        </Link>
      </li>
    );
    sitemapList.push(
      <li>
        <Link
          to={"/playground/" + playground.id + "/remark"}
          aria-label={"Jullie aanvullingen " + playground.name}
        >
          Jullie aanvullingen
        </Link>
      </li>
    );

    if (playground.externalPageUrl) {
      sitemapList.push(
        <li>
          <a
            href={playground.externalPageUrl}
            aria-label={
              "Project pagina " + playground.name + " op externe website"
            }
            target={"_blank"}
            rel={"noreferrer"}
          >
            Project pagina
          </a>
        </li>
      );
    }
    sitemapList.push(
      <li>
        <Link
          to={"/playground/" + playground.id + "/shopping-list"}
          aria-label={"Jullie boodschappenlijstje voor " + playground.name}
        >
          Boodschappenlijstje
        </Link>
      </li>
    );

    return sitemapList;
  } else if (
    phase === "AWAIT_CONCEPTS" ||
    (phase === "PREFERENCES" && playground.shoppingList.isFinalized)
  ) {
    if (playground.hasQuiz) {
      sitemapList.push(
        <li>
          <Link
            to={"/playground/" + playground.id + "/quiz"}
            aria-label={"Quiz " + playground.name}
          >
            Quiz
          </Link>
        </li>
      );
    }

    if (playground.externalPageUrl) {
      sitemapList.push(
        <li>
          <a
            href={playground.externalPageUrl}
            aria-label={
              "Project pagina " + playground.name + " op externe website"
            }
            target={"_blank"}
            rel={"noreferrer"}
          >
            Project pagina
          </a>
        </li>
      );
    }
    return sitemapList;
  } else if (phase === "CONCEPTS_VOTING") {
    if (playground.hasQuiz) {
      sitemapList.push(
        <li>
          <Link
            to={"/playground/" + playground.id + "/quiz"}
            aria-label={"Quiz " + playground.name}
          >
            Quiz
          </Link>
        </li>
      );
    }
    sitemapList.push(
      <li>
        <Link
          to={"/playground/" + playground.id + "/concepts"}
          aria-label={"Ontwerpen " + playground.name}
        >
          Ontwerpen
        </Link>
      </li>
    );

    if (playground.externalPageUrl) {
      sitemapList.push(
        <li>
          <a
            href={playground.externalPageUrl}
            aria-label={
              "Project pagina " + playground.name + " op externe website"
            }
            target={"_blank"}
            rel={"noreferrer"}
          >
            Project pagina
          </a>
        </li>
      );
    }
    return sitemapList;
  } else if (phase === "AWAIT_BUILD") {
    if (playground.hasQuiz) {
      sitemapList.push(
        <li>
          <Link
            to={"/playground/" + playground.id + "/quiz"}
            aria-label={"Quiz " + playground.name}
          >
            Quiz
          </Link>
        </li>
      );
    }

    if (playground.externalPageUrl) {
      sitemapList.push(
        <li>
          <a
            href={playground.externalPageUrl}
            aria-label={
              "Project pagina " + playground.name + " op externe website"
            }
            target={"_blank"}
            rel={"noreferrer"}
          >
            Project pagina
          </a>
        </li>
      );
    }
    return sitemapList;
  } else if (phase === "FINISHED") {
    if (playground.externalPageUrl) {
      sitemapList.push(
        <li>
          <a
            href={playground.externalPageUrl}
            aria-label={
              "Project pagina " + playground.name + " op externe website"
            }
            target={"_blank"}
            rel={"noreferrer"}
          >
            Project pagina
          </a>
        </li>
      );
    }

    return sitemapList;
  }
  return [];
};

export const getPlaygroundCurrentPhase = (
  playground: PlaygroundType
): string => {
  let phaseKey = "";
  const now = new Date();

  playground.plannedPhases.forEach((phase) => {
    const startDate = Date.parse(phase.startDate);
    const endDate = phase.endDate ? Date.parse(phase.endDate) : null;
    if (
      startDate < now.getTime() &&
      (endDate === null || endDate > now.getTime())
    ) {
      phaseKey = phase.phase;
    }
  });

  return phaseKey;
};

export const getPhaseEndDate = (
  playground: PlaygroundType,
  phaseKey: string
): Date | null => {
  let endDate = null;

  playground.plannedPhases.forEach((phase) => {
    if (phase.phase === phaseKey) {
      endDate = phase.endDate ? new Date(phase.endDate) : null;
    }
  });

  return endDate;
};

export const getTimeToGoString = (endDate: Date | null): string => {
  if (!endDate) {
    return "Eind tijd nog niet bekend!";
  }

  const diff = getTimeDifferenceInSeconds(endDate, new Date());

  if (diff < 3600) {
    return "Nog " + Math.round(diff / 60) + " minuten!";
  } else if (diff < 86400) {
    return "Nog " + Math.round(diff / 3600) + " uur!";
  } else {
    return "Nog " + Math.round(diff / 86400) + " dagen!";
  }
};

export const getTimeDifferenceInSeconds = (
  date1: Date,
  date2: Date
): number => {
  return Math.abs((date1.getTime() - date2.getTime()) / 1000);
};

export const sortPlaygroundAlphabetical = (
  playground1: PlaygroundType,
  playground2: PlaygroundType
): number => {
  if (playground1.name > playground2.name) {
    return 1;
  }
  if (playground1.name < playground2.name) {
    return -1;
  }

  return 0;
};
