import React, { useContext, useEffect, useState } from "react";
import NavigationBar from "../components/organisms/NavigationBar";
import { CheckBox } from "../components/atoms/Checkbox";
import { Link, useHistory } from "react-router-dom";
import { registerUser } from "../api/SpeeltuinchefApi";
import { SpeeltuinchefContext } from "../store/Store";
import { playgroundsFromApiToState } from "../helpers/SessionHelper";

export const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [termsOk, setTermsOk] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [registerWarningText, setRegisterWarningText] = useState("");

  const context = useContext(SpeeltuinchefContext);
  const { dispatch } = context;

  const history = useHistory();

  useEffect(() => {
    document.title = "Registreren- Speeltuinchef";
  });

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!passwordsOk()) {
      setIsLoading(false);
      return false;
    }

    if (!emailOk()) {
      setIsLoading(false);
      return false;
    }

    if (!termsOk) {
      setIsLoading(false);
      setRegisterWarningText(
        "De voorwaarden zijn niet als akkoord aangevinkt. U moet akkoord gaan met de voorwaarden om zich te kunnen registeren."
      );
      return false;
    }

    try {
      const response = await registerUser({
        email: email,
        password: password,
      });

      dispatch({ type: "SET_SESSION_TOKEN", payload: response.token });
      dispatch({ type: "SET_USER", payload: response.user });

      await playgroundsFromApiToState(context);

      history.push("/playgrounds");
    } catch (e) {
      setIsLoading(false);
      setRegisterWarningText(
        "Er is helaas iets mis gegaan, probeert u het nog eens."
      );
    }
  };

  const passwordsOk = (): boolean => {
    if (password.length < 8) {
      setRegisterWarningText(
        "Het gekozen wachtwoord is niet geldig. Het wachtwoord moet minimaal 8 tekens bevatten."
      );
      return false;
    }

    if (password.length !== repeatPassword.length) {
      setRegisterWarningText(
        "De gekozen wachtwoorden zijn niet gelijk. Type uw wachtwoord nogmaals in."
      );
      return false;
    }

    return true;
  };

  const emailOk = (): boolean => {
    if (email.length < 5) {
      setRegisterWarningText(
        "Het e-mailadres is onjuist. E-mailadres moet uit minimaal 5 tekens bestaan"
      );
      return false;
    }

    return true;
  };

  const getRegisterWarning = (): React.ReactNode | null => {
    if (registerWarningText === "" || registerWarningText === null) {
      return null;
    } else {
      return (
        <div className={"message message--warning"} role={"alert"}>
          {registerWarningText}
        </div>
      );
    }
  };

  const termsText = (
    <span>
      Ik ga akkoord met de <Link to={"/terms"}>voorwaarden</Link>
    </span>
  );

  return (
    <div>
      <NavigationBar />
      <main>
        <div className={"container login"}>
          <div className={"login__logo"}>
            <img
              src="/assets/images/chef_big.png"
              alt="De speeltuinchef. Speeltuinen koken met jouw ingrediënten! Chefkok die speeltoestellen in grote pan roert. Tijdens het roeren zegt hij 'Lekker hoor!' en vraagt hij 'Weet jij nog wat lekkere ingrediënten?'"
            />
          </div>
          <div className={"login__form"}>
            <p>
              Welkom bij De Speeltuinchef! Wilt u op de hoogte blijven van de
              speeltuinen waaraan u deelneemt? Vul dan een geldig e-mailadres
              in. De gemeente kan eventueel ook via dit e-mailadres contact met
              u opnemen.
            </p>
            <form onSubmit={handleRegister} noValidate={true}>
              <fieldset>
                <label htmlFor={"email"}>E-mailadres</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={"E-mailadres"}
                />
              </fieldset>
              <fieldset>
                <label htmlFor={"password"}>Wachtwoord</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={"Wachtwoord"}
                />
              </fieldset>
              <fieldset>
                <label htmlFor={"repeatPassword"}>Herhaal wachtwoord</label>
                <input
                  type="password"
                  name="repeatPassword"
                  id="repeatPassword"
                  autoComplete="new-password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  placeholder={"Herhaal wachtwoord"}
                />
              </fieldset>
              <div className={"login__rememberMe"}>
                <CheckBox
                  textElement={termsText}
                  checked={termsOk}
                  onChangeSetStateFunction={setTermsOk}
                  ariaText={"Ik ga akkoord met de voorwaarden"}
                />
              </div>
              {getRegisterWarning()}
              <button className={"button"}>
                {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Go!"}
              </button>
              <p>
                <Link to={"/login"}>Al een account? Log in!</Link>
              </p>
            </form>
          </div>
          <div className={"sitemap__link"}>
            <Link to={"/sitemap"} arial-label={"Bezoek de sitemap"}>
              Sitemap
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};
