import NavigationBar from "../components/organisms/NavigationBar";
import { Link } from "react-router-dom";
import { CheckBox } from "../components/atoms/Checkbox";
import React, { useContext, useEffect, useState } from "react";
import { SpeeltuinchefContext } from "../store/Store";
import { postLogin } from "../api/SpeeltuinchefApi";
import { useHistory } from "react-router-dom";
import { playgroundsFromApiToState } from "../helpers/SessionHelper";

export const LoginPage = () => {
  const history = useHistory();
  const context = useContext(SpeeltuinchefContext);
  const { dispatch } = context;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const [correctLogin, setCorrectLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Inloggen - Speeltuinchef";
  });

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await postLogin({
        email: email,
        password: password,
      });

      if (response.error) {
        setCorrectLogin(false);
        setIsLoading(false);
      } else {
        dispatch({ type: "SET_SESSION_TOKEN", payload: response.token });
        dispatch({ type: "SET_USER", payload: response.user });
        if (rememberMe) {
          localStorage.setItem("speeltuinchef-session", response.token);
        } else {
          //yes, no difference between rememberMe or not. Due to the person checking for accessibility who's tooling otherwise won't work
          localStorage.setItem("speeltuinchef-session", response.token);
        }

        await playgroundsFromApiToState(context);

        history.push("/playgrounds");
      }
    } catch (e) {
      setCorrectLogin(false);
      setIsLoading(false);
    }
  };

  const loginWarning = correctLogin ? null : (
    <div className={"message message--warning"} role={"alert"}>
      De gegeven e-mailadres en wachtwoord combinatie is niet geldig of bekend.
      Controleer uw gegevens, en probeer het opnieuw.
    </div>
  );

  return (
    <div>
      <NavigationBar />
      <main>
        <div className={"container login"}>
          <div className={"login__logo"}>
            <img
              src="/assets/images/chef_big.png"
              alt="De speeltuinchef. Speeltuinen koken met jouw ingrediënten! Chefkok die speeltoestellen in grote pan roert. Tijdens het roeren zegt hij 'Lekker hoor!' en vraagt hij 'Weet jij nog wat lekkere ingrediënten?'"
            />
          </div>
          <div className={"login__form"}>
            <form onSubmit={handleLogin}>
              <fieldset>
                <label htmlFor={"email"}>E-mailadres</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete={"email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={"E-mailadres"}
                />
              </fieldset>
              <fieldset>
                <label htmlFor={"password"}>Wachtwoord</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  autoComplete={"current-password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={"Wachtwoord"}
                />
              </fieldset>
              <div className={"login__rememberMe"}>
                <CheckBox
                  text={"Onthoud me"}
                  checked={rememberMe}
                  onChangeSetStateFunction={setRememberMe}
                  ariaText={"Onthoud me"}
                />
              </div>
              {loginWarning}
              <button className={"button"}>
                {isLoading ? <img src={"/assets/images/loader.svg"} /> : "Go!"}
              </button>
              <p>
                <Link to={"/password-forgot"}>Wachtwoord vergeten?</Link>
              </p>
              <p>
                <Link to={"/register"}>Nog geen account?</Link>
              </p>
            </form>
          </div>
          <div className={"sitemap__link"}>
            <Link to={"/sitemap"} arial-label={"Bezoek de sitemap"}>
              Sitemap
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LoginPage;
