import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import NavigationBar from "../components/organisms/NavigationBar";
import { TileExplanation } from "../components/molecules/TileExplanation";
import { SpeeltuinchefContext } from "../store/Store";
import { getPlaygroundById } from "../helpers/PlaygroundHelper";
import BottomPaginationBar from "../components/organisms/BottomPaginationBar";
import {
  getIndexArrayOfSelectedMoodboards,
  toggleMoodboard,
} from "../helpers/MoodboardHelper";
import { ChefWarning } from "../components/molecules/ChefWarning";
import { Moodboard } from "../components/molecules/Moodboard";
import { updateShoppingList } from "../api/playgrounds/PlaygroundsCalls";

export const PlaygroundMoodboardPage = () => {
  const [seenDescription, setSeenDescription] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [
    hasTooMuchMoodboardsWarning,
    setHasTooMuchMoodboardsWarning,
  ] = useState(false);
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId), context);

  const history = useHistory();

  useEffect(() => {
    document.title = "Jullie smaak - Speeltuinchef";
  });

  if (playground === null) {
    return <div role={"status"}>Laden..</div>;
  } else if (playground.moodboards.length === 0) {
    location.href = "/playground/" + playground.id;
    return <div></div>;
  }

  if (!seenDescription) {
    return (
      <div>
        <NavigationBar />
        <main>
          <TileExplanation
            imageUrl={"/assets/images/moodboard_tile.svg"}
            imageDescription={""}
            title={"Jullie smaak"}
            text={
              "Welke stijlen en vormen vind je mooi voor een speelterrein? Het gaat hier dus nog niet om de inhoud maar echt om hoe het er uit gaat zien. Gebruik de smileys om aan te geven of iets je aanspreekt of juist niet. Je mag maximaal " +
              playground.maxSelectableMoodboards +
              " stijlen kiezen. Veel plezier!"
            }
            setSeenDescription={setSeenDescription}
          />
        </main>
      </div>
    );
  } else {
    const votePositiveFunction = (page: number) => {
      const moodboard = playground.moodboards[page];

      if (playground.shoppingList.moodboards.includes(moodboard.id)) {
        return null;
      }

      if (
        playground.shoppingList.moodboards.length >=
        playground.maxSelectableMoodboards
      ) {
        setHasTooMuchMoodboardsWarning(true);
      } else {
        toggleMoodboard(moodboard, playground.shoppingList);
      }
    };

    const voteNegativeFunction = (page: number) => {
      const moodboard = playground.moodboards[page];
      if (playground.shoppingList.moodboards.includes(moodboard.id)) {
        toggleMoodboard(moodboard, playground.shoppingList);
        setHasTooMuchMoodboardsWarning(false);
      }
    };

    const tooMuchMoodboardsWarning = hasTooMuchMoodboardsWarning ? (
      <ChefWarning
        text={
          "Je mag maximaal " +
          playground.maxSelectableMoodboards +
          " stijlen selecteren!"
        }
        closeFunction={setHasTooMuchMoodboardsWarning}
      />
    ) : null;

    const saveAndContinue = async () => {
      const { state } = context;
      if (state.session.token === null) {
        location.href = "/login";
        return;
      }
      await updateShoppingList(
        state.session.token,
        playground,
        playground.shoppingList
      );
      history.push("/playground/" + playground.id);
    };

    return (
      <div>
        <NavigationBar />
        <main>
          {tooMuchMoodboardsWarning}
          <div className={"block"}>
            <h1>{playground.name}</h1>
            <Moodboard moodboard={playground.moodboards[currentPage]} />
          </div>

          <BottomPaginationBar
            count={playground.moodboards.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            hasVoting={true}
            votedPages={getIndexArrayOfSelectedMoodboards(
              playground.moodboards,
              playground.shoppingList.moodboards
            )}
            votePositiveFunction={votePositiveFunction}
            voteNegativeFunction={voteNegativeFunction}
            saveFunction={saveAndContinue}
          />
        </main>
      </div>
    );
  }
};
