import React, { useContext, useState } from "react";
import { ContextType, SpeeltuinchefContext } from "../../store/Store";
import { getPlaygroundById } from "../../helpers/PlaygroundHelper";
import { PlaygroundType } from "../../models/PlaygroundType";
import { FamilyTile } from "../molecules/FamilyTile";
import { updateShoppingList } from "../../api/playgrounds/PlaygroundsCalls";
import { useHistory } from "react-router-dom";
import { BottomActionBar } from "./BottomActionBar";

export type FamilyContainerType = {
  playgroundId: number;
};

export const FamilyContainer = (props: FamilyContainerType) => {
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(props.playgroundId, context);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  if (!playground) {
    return <div></div>;
  }

  const saveAndContinue = async () => {
    const { state } = context;
    if (state.session.token === null) {
      location.href = "/login";
      return;
    }
    setIsLoading(true);
    await updateShoppingList(
      state.session.token,
      playground,
      playground.shoppingList
    );
    history.push("/playground/" + playground.id);
  };

  return (
    <div className={"block"}>
      <h1>{playground.name}</h1>

      <div className={"playgroundDashboard"}>
        {getFamilyMembers(playground, context)}
      </div>
      <BottomActionBar
        text={"Verder"}
        isLoading={isLoading}
        clickFunction={saveAndContinue}
      />
    </div>
  );
};

const getFamilyMembers = (
  playground: PlaygroundType,
  context: ContextType
): React.ReactNode => {
  return playground.family.map((familyMember) => {
    return (
      <FamilyTile
        key={familyMember.id}
        familyMember={familyMember}
        context={context}
        playground={playground}
      />
    );
  });
};
