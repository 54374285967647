import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "../page/LoginPage";
import HomePage from "../page/HomePage";
import React, { useContext, useEffect, useState } from "react";
import { SpeeltuinchefContext } from "../store/Store";
import { checkAndLoadState, hasValidSession } from "../helpers/SessionHelper";
import { PlaygroundsPage } from "../page/PlaygroundsPage";
import { Loading } from "../components/molecules/Loading";
import { PersonalPage } from "../page/PersonalPage";
import { Logout } from "../components/organisms/Logout";
import { PlaygroundPage } from "../page/PlaygroundPage";
import { PlaygroundIngredientsPage } from "../page/PlaygroundIngredientsPage";
import { PlaygroundFamilyPage } from "../page/PlaygroundFamilyPage";
import { PlaygroundMoodboardPage } from "../page/PlaygroundMoodboardPage";
import { PlaygroundQuizPage } from "../page/PlaygroundQuizPage";
import { PlaygroundRemarkPage } from "../page/PlaygroundRemarkPage";
import { RegisterPage } from "../page/RegisterPage";
import { TermsPage } from "../page/TermsPage";
import { PasswordResetPage } from "../page/PasswordResetPage";
import { PlaygroundActivitiesPage } from "../page/PlaygroundActivitiesPage";
import { PlaygroundQuestionnairePage } from "../page/PlaygroundQuestionnairePage";
import { PlaygroundShoppinglistPage } from "../page/PlaygroundShoppinglistPage";
import { PlaygroundConceptsPage } from "../page/PlaygroundConceptsPage";
import SitemapPage from "../page/SitemapPage";

export default function SpeeltuinchefRoute() {
  const context = useContext(SpeeltuinchefContext);
  const { state } = context;
  const [isLoadingState, setIsLoadingState] = useState(false);

  useEffect(() => {
    if (!isLoadingState && hasValidSession(context)) {
      setIsLoadingState(true);
      checkAndLoadState(context);
    }
  });

  const routes = hasValidSession(context)
    ? state.user === undefined || state.user.email === null
      ? LoadingRoute()
      : LoggedInRoute()
    : OpenRoute();

  return <Router>{routes}</Router>;
}

export const OpenRoute = () => {
  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/register">
        <RegisterPage />
      </Route>
      <Route path="/terms">
        <TermsPage />
      </Route>
      <Route path="/password-forgot/:resetToken">
        <PasswordResetPage />
      </Route>
      <Route path="/password-forgot">
        <PasswordResetPage />
      </Route>
      <Route path="/sitemap">
        <SitemapPage />
      </Route>
      <Route path="/">
        <HomePage />
      </Route>
    </Switch>
  );
};

export const LoggedInRoute = () => {
  return (
    <Switch>
      <Route path="/personal">
        <PersonalPage />
      </Route>
      <Route path="/playgrounds">
        <PlaygroundsPage />
      </Route>
      <Route path="/playground/:playgroundId/ingredients">
        <PlaygroundIngredientsPage />
      </Route>
      <Route path="/playground/:playgroundId/family">
        <PlaygroundFamilyPage />
      </Route>
      <Route path="/playground/:playgroundId/quiz">
        <PlaygroundQuizPage />
      </Route>
      <Route path="/playground/:playgroundId/remark">
        <PlaygroundRemarkPage />
      </Route>
      <Route path="/playground/:playgroundId/moodboard">
        <PlaygroundMoodboardPage />
      </Route>
      <Route path={"/playground/:playgroundId/activity"}>
        <PlaygroundActivitiesPage />
      </Route>
      <Route path={"/playground/:playgroundId/questionnaire"}>
        <PlaygroundQuestionnairePage />
      </Route>
      <Route path={"/playground/:playgroundId/shopping-list"}>
        <PlaygroundShoppinglistPage />
      </Route>
      <Route path={"/playground/:playgroundId/concepts"}>
        <PlaygroundConceptsPage />
      </Route>
      <Route path="/playground/:playgroundId">
        <PlaygroundPage />
      </Route>
      <Route path="/terms">
        <TermsPage />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/sitemap">
        <SitemapPage />
      </Route>
      <Route path="/">
        <PlaygroundsPage />
      </Route>
    </Switch>
  );
};

export const LoadingRoute = () => {
  return (
    <Switch>
      <Route path="/">
        <Loading />
      </Route>
    </Switch>
  );
};
