import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SpeeltuinchefContext } from "../../store/Store";
import { getPlaygroundById } from "../../helpers/PlaygroundHelper";
import { updateShoppingList } from "../../api/playgrounds/PlaygroundsCalls";

export const Remark = () => {
  const { playgroundId } = useParams<{ playgroundId: string }>();
  const context = useContext(SpeeltuinchefContext);
  const playground = getPlaygroundById(parseInt(playgroundId), context);
  const history = useHistory();

  if (playground === null) {
    return <div role={"status"}>Laden..</div>;
  }
  const [remark, setRemark] = useState(playground.shoppingList.remark);

  const changeTextareaValue = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setRemark(event.target.value === "" ? null : event.target.value);
  };

  const saveAndContinue = async () => {
    const { state } = context;
    if (state.session.token === null) {
      location.href = "/login";
      return;
    }
    playground.shoppingList.remark = remark;
    await updateShoppingList(
      state.session.token,
      playground,
      playground.shoppingList
    );
    history.push("/playground/" + playground.id);
  };

  return (
    <div className={"block"}>
      <h1>{playground.name}</h1>
      <div className={"shoppingList"}>
        <section>
          <h2>Jouw aanvullingen</h2>
        </section>
        <section>
          <fieldset>
            <label htmlFor={"remark"}>
              Voeg eventuele extra opmerkingen toe
            </label>
            <br />
            <textarea
              name={"remark"}
              id="remark"
              placeholder={"Voeg eventuele extra opmerkingen toe"}
              onChange={changeTextareaValue}
              value={remark ? remark : ""}
            />
          </fieldset>
        </section>
        <section>
          <button
            className={"button"}
            onClick={() => {
              saveAndContinue();
            }}
          >
            Voeg toe
          </button>
        </section>
      </div>
    </div>
  );
};
